import React from "react";

function Text() {
  return (
      <section id="dostavka" className="text">
        <div className="text__content container"><h2>Условия доставки!</h2>
          <div><p>Малое Верево, Большое Верево, Вайя, Верево Сити <br/>
              <span>«доставка до 800 рублей платная 150 рублей, от 800 рублей бесплатная»</span>
            <br/><br/>
              Микрорайон Аэродром, микрорайон Мариенбург, Пригородный, Торфяное, Тайцы <br/>
              <span>«доставка до 1800 рублей платная 350 рублей, от 1800 рублей бесплатная, минимальный заказ 1500р» </span>
            <br/><br/>Гатчина, Романовка, Новый Свет, Торфопредприятие, Горки, Перелево, Кирлово, Руссолово, Крайново,
            СНТ Мостновик, СНТ Скороход, ДМП Родник, Горки Лэнд (все номера), Бугры, СНТ Ижора, Вайялово <br/>
              <span>«доставка до 1200 рублей платная 250 рублей, от 1200 рублей бесплатная, минимальный заказ 1000р»</span><br/><br/>Колпаны,
            Химози, Д.Нижняя, Сокколово, Тихвинка, Парицы, Бор, Лукаши, Д.Котельниково, Дони, Хвойный <br/>
              <span>«доставка до 2500 рублей платная 450 рублей, от 2500 рублей бесплатная, минимальный заказ 2100р»</span>
            <br/><br/></p></div>
        </div>
      </section>
  );
}

export default Text;
