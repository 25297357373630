import pizza from "./pizza";
import kalcone from "./kalcone";
import deserti from "./deserti";
import combo from "./combo";
import rolli from "./rolli";
import zapechRolli from "./zapech-rolli";
import tempura from "./tempura";
import wok from "./wok";
import hot_kitchen from "./hot_kitchen";
import soup from "./soup";
import garnir from "./garnir";

export const catalogList = [combo, pizza, rolli, zapechRolli, tempura, wok, hot_kitchen, kalcone, soup, garnir, deserti];
