export default {
  name: "Закуски",
  anchor: "garnir",
  items: [
    {
      name: "Наггетсы и картофель фри",
      img: "/images/menu/наггетсы, фри_.JPG",
      alt: "",
      desc: "картофель, куриные наггетсы\n",
      price: 250,
      size: null,
    },
    {
      name: "Картофель фри ",
      img: "/images/menu/Картофель фри_.JPG",
      alt: "",
      desc: "картофель, кетчуп",
      price: 180,
      size: null,
    },
    {
      name: "Наггетсы с кетчупом ",
      img: "/images/menu/наггетсы - 9_.JPG",
      alt: "",
      desc: "куриная грудка, панировка, кетчуп (6шт)",
      price: 220,
      size: null,
    },
    {
      name: "Наггетсы с кетчупом ",
      img: "/images/menu/наггетсы - 9_.JPG",
      alt: "",
      desc: "куриная грудка, панировка, кетчуп (9шт)",
      price: 300,
      size: null,
    },
  ],
};
