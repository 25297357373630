export default {
  name: "Супы и салаты",
  anchor: "soup",
  items: [
    {
      name: "Уха по-фински",
      img: "/images/menu/Уха по фински_.JPG",
      alt: "",
      desc: "лосось, лук, морковь, картофель, сливки, укроп\n",
      price: 449,
      size: null,
    },
    {
      name: "Мисо Классик",
      img: "/images/menu/Мисо суп классик_.JPG",
      alt: "",
      desc: "мисо паста, сыр тофу, шиитаке, вакамэ, лук зеленный",
      price: 249,
      size: null,
    },
    {
      name: "Мисо острый",
      img: "/images/menu/Миссо суп острый_.JPG",
      alt: "",
      desc: "мисо паста, сыр тофу, шиитаке, вакамэ, лук зелёный, свинина\n",
      price: 349,
      size: null,
    },
    {
      name: "Том ям с курицей",
      img: "/images/menu/Том ям с курицей_.JPG",
      alt: "",
      desc: "курица, шампиньоны, черри, кокосовое молоко, кинза, база том ям, рис, лайм\n",
      price: 499,
      size: null,
    },
    {
      name: "Том ям с креветкой и кальмаром",
      img: "/images/menu/Том ям морской_.JPG",
      alt: "",
      desc: "креветки, кальмар, шампиньоны, черри, кинза, рис, лайм, кокосовое молоко, база том ям\n",
      price: 549,
      size: null,
    },
    {
      name: "Чука",
      img: "/images/menu/Чука салат_.JPG",
      alt: "",
      desc: "чука, ореховый соус, вакаме, лимон\n",
      price: 249,
      size: null,
    },
    {
      name: "Цезарь",
      img: "/images/menu/Салат цезарь с курицей_.JPG",
      alt: "",
      desc: "цезарь-соус, салат айсберг, курица, черри, сыр пармезан, яйцо перепелиное, гренки",
      price: 499,
      size: null,
    },
    {
      name: "Греческий",
      img: "/images/menu/Салат греческий_.JPG",
      alt: "",
      desc: "сыр фета, огурец, томаты, паприка, лук красный, маслины, оливковое масло",
      price: 399,
      size: null,
    },
  ],
};
