export default {
  name: "Пицца",
  anchor: "pizza",
  items: [
      {
      name: "Цезарь",
      img: "/images/menu/Пицца цезарь_.JPG",
      alt: "",
      desc: "белый соус, курица, томаты, сыр моцарелла, сыр пармезан, салат айсберг, цезарь-соус",
      price: [790],
      size: [33],
    },
      {
      name: "Четыре сезона",
      img: "/images/menu/Пицца 4 сезона_.JPG",
      alt: "",
      desc: "красный соус, сыр моцарелла, пепперони, ветчина, шампиньоны, томаты, сыр горгонзола",
      price: [799],
      size: [33],
    },

      {
          name: "Цыпленок ранч",
          img: "/images/menu/Пицца цыпленок ранч_.JPG",
          alt: "",
          desc: "сырный соус, сыр моцарелла, курица, томаты",
          price: [620, 790],
          size: [28, 33],
      },
      {
          name: "Лосось с рукколой",
          img: "/images/menu/Пицца с лососем и руколой_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, лосось, руколла",
          price: [795],
          size: [28],
      },
      {
          name: "Деревенская",
          img: "/images/menu/Пицца деревенская_.JPG",
          alt: "",
          desc: "красный соус, колбаски охотничьи, лук красный, огурцы маринованные, шампиньоны, картошка, горчичный соус",
          price: [610],
          size: [28],
      },
      {
          name: "Дьябло",
          img: "/images/menu/Пицца дьябло_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, бекон, пепперони, халапеньо, перец болгарский, кимчи-соус",
          price: [690],
          size: [28],
      },
      {
          name: "Греческая",
          img: "/images/menu/Пицца греческая_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, лук красный, томаты, маслины, салат айсберг, сыр фета, оливковое масло\n",
          price: [599],
          size: [28],
      },
      {
          name: "Славянская",
          img: "/images/menu/Пицца славянская1_.JPG",
          alt: "",
          desc: "красный соус, фасоль красная, лук красный, кукуруза, перец болгарский, халапеньо, томаты, сыр пармезан",
          price: [589],
          size: [28],
      },
      {
          name: "Пепперони",
          img: "/images/menu/Пицца пеперони_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, пепперони",
          price: [599, 699],
          size: [28, 33],
      },
      {
          name: "Карбонара",
          img: "/images/menu/Пицца карбонара_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, бекон, желток",
          price: [599, 715],
          size: [28, 33],
      },
      {
          name: "Пицца СВ",
          img: "/images/menu/Пицца СВ-2_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, ветчина, сырный соус",
          price: [599, 699],
          size: [28, 33],
      },
      {
          name: "Паццио",
          img: "/images/menu/ee4c064.jpeg",
          alt: "",
          desc: "красный соус, сыр моцарелла, бекон, лук красный, курица, барбекю соус",
          price: [630, 725],
          size: [28, 33],
      },
      {
          name: "Двойная пепперони",
          img: "/images/menu/Пицца пеперони двойная_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, пепперони",
          price: [799],
          size: [33],
      },
      {
          name: "Четыре сыра",
          img: "/images/menu/Пицца 4 сыра_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, сыр сулугуни, сыр имеретинский, сыр горгонзола",
          price: [630, 749],
          size: [28, 33],
      },
      {
          name: "Гавайская",
          img: "/images/menu/Пицца гавайская_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, курица, ананасы, сырный соус",
          price: [590, 699],
          size: [28, 33],
      },
      {
          name: "Венеция терияки",
          img: "/images/menu/Пицца венеция терияки_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, курица, картошка, лук красный, терияки соус",
          price: [615, 720],
          size: [28, 33],
      },
      {
          name: "Чикен в xату",
          img: "/images/menu/Пицца чикин в хату_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, бекон, курица, лук красный, перец болгарский, томаты, белый соус",
          price: [650, 790],
          size: [28, 33],
      },
      {
          name: "Сырная",
          img: "/images/menu/Пицца сырная_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла",
          price: [540, 610],
          size: [28, 33],
      },
      {
          name: "Маргарита",
          img: "/images/menu/Пицца маргарита_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, томаты",
          price: [560, 650],
          size: [28, 33],
      },
      {
          name: "Тиамо",
          img: "/images/menu/Пицца тиамо_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, охотничьи колбаски, пепперони, ветчина, курица",
          price: [640, 730],
          size: [28, 33],
      },
      {
          name: "Мачо",
          img: "/images/menu/Пицца мачо_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, охотничьи колбаски, маринованные огурчики, чеснок",
          price: [685],
          size: [28],
      },
      {
          name: "Дэлисия",
          img: "/images/menu/7ef97ca.jpeg",
          alt: "",
          desc: "красный соус, сыр моцарелла, пепперони, маслины, шампиньоны",
          price: [599, 695],
          size: [28, 33],
      },
      {
          name: "Папа хочет",
          img: "/images/menu/Пицца папа может_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, охотничьи колбаски, бекон, чеснок, БОРТ охотничьи колбаски",
          price: [785],
          size: [28],
      },
      {
          name: "Прошутто с рукколой",
          img: "/images/menu/Пицца прошутто с руколой_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, прошутто, руккола",
          price: [695],
          size: [28],
      },
      {
          name: "Верона",
          img: "/images/menu/Пицца верона_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, курица, шампиньоны, ветчина, лук красный",
          price: [620, 710],
          size: [28, 33],
      },
      {
          name: "Тоскана",
          img: "/images/menu/Пицца соренто_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, курица, ветчина, охотничьи колбаски",
          price: [615, 720],
          size: [28, 33],
      },
      {
          name: "Соренто",
          img: "/images/menu/Пицца тоскана_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, курица, ветчина, пепперони",
          price: [649, 749],
          size: [28, 33],
      },
      {
          name: "Морская",
          img: "/images/menu/Пицца морская с терияки_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, креветки, мидии, маслины, лук красный, терияки соус",
          price: [750],
          size: [28],
      },
      {
          name: "Вегано",
          img: "/images/menu/981528cp.jpeg",
          alt: "",
          desc: "красный соус, сыр моцарелла, томаты, маслины, перец болгарский, лук красный, цукини, соус песто",
          price: [650],
          size: [33],
      },
      {
          name: "Шаверма Курица",
          img: "/images/menu/Пицца шаверма курица_.JPG",
          alt: "",
          desc: "чесночный соус, сыр моцарелла, лук красный, огурцы маринованные, куриная грудка маринованная, томаты",
          price: [730],
          size: [33],
      },
      {
          name: "Шаверма Говядина",
          img: "/images/menu/Пицца шаверма говядина_.JPG",
          alt: "",
          desc: "чесночный соус, сыр моцарелла, лук красный, огурцы маринованные, говядина маринованная",
          price: [810],
          size: [33],
      },
      {
          name: "Сырная на чеддере",
          img: "/images/menu/Пицца сырная на чедере_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, сыр имеретинский, сыр сулугуни, сыр чеддер",
          price: [745],
          size: [33],
      },
      {
          name: "Мясное ассорти",
          img: "/images/menu/Пицца мясное ассорти_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, руляда куриная, карбонад, шейка свиная, бекон",
          price: [820],
          size: [33],
      },
      {
          name: "Феличита",
          img: "/images/menu/Пицца феличита_.JPG",
          alt: "",
          desc: "красный соус, сыр моцарелла, томаты, маслины, перец болгарский, лук красный, шампиньоны",
          price: [670],
          size: [33],
      },
      {
          name: "Акуто на ржаном тесте",
          img: "/images/menu/Пицца акуто на ржаном_.JPG",
          alt: "",
          desc: "ржаное тесто, красный соус, сыр моцарелла, курица маринованная, ветчина, охотничьи колбаски, лук красный",
          price: [750],
          size: [33],
      },
      {
          name: "Мясная на ржаном тесте",
          img: "/images/menu/Пицца мясная на ржаном_ (2).JPG",
          alt: "",
          desc: "ржаное тесто, красный соус, сыр моцарелла, курица маринованная, говядина маринованная, охотничьи колбаски",
          price: [810],
          size: [33],
      },
      {
          name: "Сегале на ржаном тесте",
          img: "/images/menu/Пицца сегале на ржаном.JPG",
          alt: "",
          desc: "ржаное тесто, красный соус, сыр моцарелла, бекон, лук красный, чеснок",
          price: [735],
          size: [33],
      },
      {
          name: "Грибная",
          img: "/images/menu/Пицца грибная_ (2).JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, лук красный, опята",
          price: [699],
          size: [28],
      },
      {
          name: "Крабовая с рукколой",
          img: "/images/menu/Пицца крабовая палочка с рукколой_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, кукуруза, томаты, лук красный, крабовые палочки, руккола",
          price: [599],
          size: [28],
      },
      {
          name: "Грушевая",
          img: "/images/menu/Пицца грушевая_.JPG",
          alt: "",
          desc: "белый соус, сыр моцарелла, сыр горгонзола, груша, мед",
          price: [590],
          size: [28],
      },
      {
          name: "Ягодная со сгущенкой",
          img: "/images/menu/Пицца ягодная со сгущенкой_.JPG",
          alt: "",
          desc: "сгущённое молоко, малина, ежевика, красная смородина, черника, черная смородина",
          price: [745],
          size: [28],
      },
      {
          name: "Примавера",
          img: "/images/menu/4ee3d.jpeg",
          alt: "",
          desc: "красный соус, сыр моцарелла, томаты, картофель, маринованные огурцы, перец болгарский, лук красный, курица",
          price: [675],
          size: [33],
      },
      {
          name: "Хачапури по-грузински",
          img: "/images/menu/e343606d8792.jpeg",
          alt: "",
          desc: "сыр моцарелла, сыр имеретинский, сыр сулугуни",
          price: [645, 740],
          size: [28, 33],
      },

  ],
};
