export default {
  name: "WOK",
  anchor: "wok",
  items: [
    {
      name: "Удон с курицей",
      img: "/images/menu/Удон с курицей_.JPG",
      alt: "",
      desc: "Лапша удон, морковь, перец болгарский, лук, курица, кабачки, масло подсолнечное, кунжут\n",
      price: 349,
      size: null,
    },
    {
      name: "Удон со свининой",
      img: "/images/menu/Удон со свининой_.JPG",
      alt: "",
      desc: "Лапша удон, морковь, перец болгарский, лук, свинина, кабачки, масло подсолнечное, кунжут\n",
      price: 370,
      size: null,
    },
    {
      name: "Удон с говядиной",
      img: "/images/menu/Удон с говядиной_.JPG",
      alt: "",
      desc: "Лапша удон, морковь, перец болгарский, лук, говядина, кабачки, масло подсолнечное, кунжут",
      price: 399,
      size: null,
    },
    {
      name: "Удон с морепродуктами",
      img: "/images/menu/Удон морепродукт_.JPG",
      alt: "",
      desc: "кальмар, осьминог, креветка, мидии, лапша удон, морковь, кабачки, лук, перец болгарский, масло подсолнечное, кунжут",
      price: 449,
      size: null,
    },
    {
      name: "Рис с курицей и овощами",
      img: "/images/menu/Тахан курица_.JPG",
      alt: "",
      desc: "Рис, морковь, перец болгарский, лук, курица, кабачки, масло подсолнечное, кунжут\n",
      price: 349,
      size: null,
    },
    {
      name: "Рис со свининой и овощами",
      img: "/images/menu/Тахан свинина_.JPG",
      alt: "",
      desc: "Рис, морковь, перец болгарский, лук, свинина, кабачки, масло подсолнечное, масло кунжутное, кунжут, зелень",
      price: 370,
      size: null,
    },
    {
      name: "Рис с говядиной и овощами",
      img: "/images/menu/Тахан говядина_.JPG",
      alt: "",
      desc: "Рис, морковь, перец болгарский, лук, говядина, кабачки, масло подсолнечное, кунжут\n",
      price: 399,
      size: null,
    },
    {
      name: "Рис с морепродуктами",
      img: "/images/menu/Тахан курица_.JPG",
      alt: "",
      desc: "кальмар, осьминог, креветка, мидии, рис, морковь, кабачки, лук, масло подсолнечное\n",
      price: 449,
      size: null,
    },
  ],
};
