export default {
  name: "Комбо и наборы",
  anchor: "combo",
  items: [
    {
      name: "Выгодный перекус",
      img: "/images/menu/пеперони + карбонара.jpg",
      alt: "",
      desc: "Пепперони (28 см), Карбонара (28 см)",
      price: 1049,
      size: null,
    },
    {
      name: "Легкий перекус",
      img: "/images/menu/Комбо Легкий перекус 28см.jpg",
      alt: "",
      desc: "Пиццы Сырная (28 см), Маргарита (28 см), Дэлисия (28см)",
      price: 1479,
      size: null,
    },
    {
      name: "Мясной перекус",
      img: "/images/menu/Комбо Мясной перекус 28см.jpg",
      alt: "",
      desc: "Пиццы: Тиамо (28 см), Пепперони (28 см), Паццио (28 см), Мачо (28 см)",
      price: 2199,
      size: null,
    },
    {
      name: "Большая команда",
      img: "/images/menu/Комбо большая команда.jpg",
      alt: "",
      desc: "Пиццы Карбонара (33 см), Феличита (33 см), Венеция (33 см), Тиамо (33 см), Папа хочет (28 см)",
      price: 3149,
      size: null,
    },
    {
      name: "Набор для двоих",
      img: "/images/menu/Комбо для двоих копия.jpg",
      alt: "",
      desc: "Ролл Филадельфия, пицца Пепперони (28 см), пицца СВ (28 см), кола Добрый (1 л)",
      price: 1699,
      size: null,
    },
    {
      name: "Сет №1",
      img: "/images/menu/5e76f6896.jpeg",
      alt: "",
      desc: "Филадельфия, Канада, маки с тунцом, маки с лососем",
      price: 1599,
      size: null,
    },
    {
      name: "Сет №2",
      img: "/images/menu/0V4A8832 .jpg",
      alt: "",
      desc: "Опаленная Филадельфия, Калифорния, маки с угрем, маки с огурцом",
      price: 1250,
      size: null,
    },
    {
      name: "Сет №3",
      img: "/images/menu/c16aa9a40e.jpeg",
      alt: "",
      desc: "Канада, Меркурий, Дракон-ролл, маки с угрем",
      price: 1999,
      size: null,
    },
    {
      name: "Сет №4",
      img: "/images/menu/0V4A8551 .jpg",
      alt: "",
      desc: "Канада, Филадельфия, Эби темпура, маки с угрем, маки с лососем",
      price: 2099,
      size: null,
    },
    {
      name: "Сет №5",
      img: "/images/menu/0V4A8725 .jpg",
      alt: "",
      desc: "Филадельфия, Эби лайт, Аляска, Спайси-лосось, Запеченный угорь, Запеченный с креветкой, Запеченный лосось терияки",
      price: 3449,
      size: null,
    },
    {
      name: "Темпура сет",
      img: "/images/menu/0V4A8643 .jpg",
      alt: "",
      desc: "Якудза-ролл, Сахара-ролл, Темпура эби",
      price: 1499,
      size: null,
    },
    {
      name: "Запеченный сет №1",
      img: "/images/menu/d2e4518e.jpeg",
      alt: "",
      desc: "Запеченная Калифорния, Запеченный с креветкой, Темпура-эби",
      price: 1595,
      size: null,
    },
    {
      name: "Запеченный сет №2",
      img: "/images/menu/0V4A8766 .jpg",
      alt: "",
      desc: "Спешл ролл, запеченный император, запеченный лосось терияки, запеченный от шефа\n",
      price: 1880,
      size: null,
    },
  ],
};
