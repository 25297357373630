export default {
  name: "Голячие блюда",
  anchor: "hot",
  items: [
    {
      name: "Плов",
      img: "/images/menu/Плов_.JPG",
      alt: "",
      desc: "Рис, говядина, лук, морковь",
      price: 350,
      size: null,
    },
  ],
};
