export default {
  name: "Кальцоне и чебуреки",
  anchor: "kalcone",
  items: [
    {
      name: "Сет из пяти чебуреков",
      img: "/images/menu/f1p.jpeg",
      alt: "",
      desc: "Чебурек из свинины (2 шт.), Чебурек из говядины (2 шт.), Чебурек Сыр-ветчина (1 шт.)",
      price: 850,
      size: null,
    },
    {
      name: "Сет из трех чебуреков",
      img: "/images/menu/7c85057p.jpeg",
      alt: "",
      desc: "Чебурек со свининой, Чебурек с говядиной, Чебурек с сыром",
      price: 500,
      size: null,
    },
    {
      name: "Чебурек Свинина-говядина",
      img: "/images/menu/Чебурек со свининой_.JPG",
      alt: "",
      desc: "говядина, свинина, лук\n",
      price: 185,
      size: null,
    },
    {
      name: "Чебурек Баранина",
      img: "/images/menu/Чебурек с бараниной _.JPG",
      alt: "",
      desc: "бараний фарш, лук\n",
      price: 185,
      size: null,
    },
    {
      name: "Чебурек Сыр",
      img: "/images/menu/Чебурек с сыром_.JPG",
      alt: "",
      desc: "сыр сулугуни, сыр имеретинский, сыр моцарелла",
      price: 185,
      size: null,
    },
    {
      name: "Чебурек Сыр-ветчина",
      img: "/images/menu/Чебурек с сыром и ветчиной_.JPG",
      alt: "",
      desc: "сыр моцарелла, ветчина",
      price: 185,
      size: null,
    },
    {
      name: "Кальцоне со свининой",
      img: "/images/menu/2342345235.png",
      alt: "",
      desc: "свинина, сыр моцарелла, соус",
      price: 599,
      size: null,
    },
    {
      name: "Реал кальцоне со свининой",
      img: "/images/menu/2342345235.png",
      alt: "",
      desc: "свинина, сыр моцарелла, шампиньоны, перец болгарский, ветчина, лук красный, соус",
      price: 799,
      size: null,
    },
    {
      name: "Кальцоне с курицей",
      img: "/images/menu/2342345235.png",
      alt: "",
      desc: "курица, сыр моцарелла, соус",
      price: 560,
      size: null,
    },
    {
      name: "Реал кальцоне с курицей",
      img: "/images/menu/2342345235.png",
      alt: "",
      desc: "курица, сыр моцарелла, шампиньоны, перец болгарский, ветчина, лук красный, соус",
      price: 699,
      size: null,
    },
  ],
};
