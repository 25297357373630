export default {
  name: "Напитки и десерты",
  anchor: "deserti",
  items: [
    {
      name: "Мини-безе",
      img: "/images/menu/Meringue-3.webp",
      alt: "",
      desc: "сахар, белок",
      price: 89,
      size: null,
    },    {
      name: "Трайфл бисквит с фруктовой начинкой",
      img: "/images/menu/photo_2025-01-15_21-46-11.jpg",
      alt: "",
      desc: "в ассортименте",
      price: 230,
      size: null,
    },    {
      name: " Трайфл брауни ",
      img: "/images/menu/photo_2025-01-15_21-46-10.jpg",
      alt: "",
      desc: "в ассортименте",
      price: 230,
      size: null,
    },    {
      name: "Морс клюквенный",
      img: "/images/menu/Морс клюква_.JPG",
      alt: "",
      desc: "Вода, сахар, клюква (500мл)",
      price: 110,
      size: null,
    },
    {
      name: "Компот",
      img: "/images/menu/Компот_.JPG",
      alt: "",
      desc: "Из сухофруктов (500мл)",
      price: 110,
      size: null,
    },
    {
      name: "Кола Добрый",
      img: "/images/menu/d9f8a4e3.jpeg",
      alt: "",
      desc: "Напиток газированный (0.33)",
      price: 95,
      size: null,
    },
    {
      name: "Кола Добрый",
      img: "/images/menu/059758a5.jpeg",
      alt: "",
      desc: "Напиток газированный (0.5)",
      price: 125,
      size: null,
    },
    {
      name: "Кола Добрый",
      img: "/images/menu/8888c9.jpeg",
      alt: "",
      desc: "Напиток газированный (1л)",
      price: 180,
      size: null,
    },
    {
      name: "Добрый Апельсин",
      img: "/images/menu/737323ef961.jpeg",
      alt: "",
      desc: "Напиток газированный (0.33)",
      price: 95,
      size: null,
    },
    {
      name: "Добрый Апельсин",
      img: "/images/menu/1669bbd.jpeg",
      alt: "",
      desc: "Напиток газированный (0.5)",
      price: 125,
      size: null,
    },
    {
      name: "Добрый Апельсин",
      img: "/images/menu/bfe605d06.jpeg",
      alt: "",
      desc: "Напиток газированный (1л)",
      price: 180,
      size: null,
    },
    {
      name: "Лимон-Лайм Добрый",
      img: "/images/menu/73b328252b3f402c88ff75ecc47e303e-400x400nocrop.jpeg",
      alt: "",
      desc: "Напиток газированный (0.33)",
      price: 95,
      size: null,
    },
    {
      name: "Лимон-Лайм Добрый",
      img: "/images/menu/7b704a300ad54575b1ae2ee27c831749-400x400nocrop.jpeg",
      alt: "",
      desc: "Напиток газированный (0.5)",
      price: 125,
      size: null,
    },
    {
      name: "Холодный чай Rich",
      img: "/images/menu/R_T_500_lemon.jpg",
      alt: "",
      desc: "со вкусом лимона (0.5)",
      price: 115,
      size: null,
    },
    {
      name: "Холодный чай Rich",
      img: "/images/menu/R_1000L_F_Green.jpg",
      alt: "",
      desc: "зеленый (0.5)",
      price: 115,
      size: null,
    },
    {
      name: "Волчок",
      img: "/images/menu/15367.webp",
      alt: "",
      desc: "персик (0.45)",
      price: 110,
      size: null,
    },
    {
      name: "Волчок",
      img: "/images/menu/638972.jpg",
      alt: "",
      desc: "манго кокос (0.45)",
      price: 110,
      size: null,
    },
    {
      name: "Волчок",
      img: "/images/menu/3j4ioaozvj.jpg",
      alt: "",
      desc: "арбуз (0.45)",
      price: 110,
      size: null,
    },
    {
      name: "Волчок",
      img: "/images/menu/g3fiiiad.jpg",
      alt: "",
      desc: "кола (0.45)",
      price: 110,
      size: null,
    },
    {
      name: "Волчок",
      img: "/images/menu/7114969659.jpg",
      alt: "",
      desc: "личи груша (0.45)",
      price: 110,
      size: null,
    },
    {
      name: "Simpa Tea",
      img: "/images/menu/6545435256.jpg",
      alt: "",
      desc: "Чай в ассортименте",
      price: 100,
      size: null,
    },
  ],
};
