import React from "react";
import Cart from "./Cart";
import Basket from "./Basket/Basket";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";

function Footer() {
  const isBasketOpen = useSelector((state) => state.isBasketOpen);

  return (
    <footer className="main-footer">
      <div className="footer__row container">
        <a href="#" className="header__logo-link">
          <img src="/images/logo.svg" alt="" className="header__logo-image" />
        </a>
        <nav className="menu">
          <ul className="social-menu">
            <li className="social-menu__item">
              <a href="https://www.instagram.com/pizzaverevo/?igshid=rg8bp6eqsmuq" className="social-menu__link">
                <img src="/images/insta.png" className="social-link__img"/>
              </a>
            </li>
            <li className="social-menu__item">
              <a href="https://vk.com/pizza_verevo" className="social-menu__link">
                <img src="/images/vk.png" className="social-link__img"/>
              </a>
            </li>
          </ul>
          <ul className="menu__list">
            <li className="menu__item">
              <Link to="kombo" smooth={true} offset={-50} className="menu__link">
                Комбо и наборы
              </Link>
            </li>
            <li className="menu__item">
              <Link to="pizza" smooth={true} offset={-50} className="menu__link">
                Пицца
              </Link>
            </li>
            <li className="menu__item">
              <Link to="sushi" smooth={true} offset={-50} className="menu__link">
                Роллы и суши
              </Link>
            </li>
            <li className="menu__item">
              <Link to="zapech-rolli" smooth={true} offset={-50} className="menu__link">
                Запеченные роллы
              </Link>
            </li>
            <li className="menu__item">
              <Link to="tempura-rolli" smooth={true} offset={-50} className="menu__link">
                Темпура роллы
              </Link>
            </li>
            <li className="menu__item">
              <Link to="wok" smooth={true} offset={-50} className="menu__link">
                WOK
              </Link>
            </li>
            <li className="menu__item">
              <Link to="chebureki" smooth={true} offset={-50} className="menu__link">
                Кальцоне и чебуреки
              </Link>
            </li>
            <li className="menu__item">
              <Link to="chebureki" smooth={true} offset={-50} className="menu__link">
                Супы и салаты
              </Link>
            </li>
            <li className="menu__item">
              <Link to="zakuski" smooth={true} offset={-50} className="menu__link">
                Закуски
              </Link>
            </li>
            <li className="menu__item">
              <Link to="deserti" smooth={true} offset={-50} className="menu__link">
                Десерты
              </Link>
            </li>
            <li className="menu__item">
              <Link to="napitki" smooth={true} offset={-50} className="menu__link">
                Напитки
              </Link>
            </li>
            <li className="menu__item">
              <Link to="napitki" smooth={true} offset={-50} className="menu__link">
                Напитки
              </Link>
            </li>
          </ul>
        </nav>

        <div className="footer__contacts">
          <a href="mailto:pizza@finger-food.ru">pizza@finger-food.ru</a>
          <a href="tel:+79216320626">+7 (921) 632 0 626</a>
          <p className="footer__contacts-description">
            Принимаем заказы: <br/>
            Ежедневно с 11.00 до 20.30 <br/>
            Внимание! Следите за нашими акциями!!!
          </p>
        </div>
      </div>

      <Cart/>
      {isBasketOpen && <Basket/>}
    </footer>
  );
}

export default Footer;
